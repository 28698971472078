<template>
  <div class="site">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>站点设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form ref="formRef" :model="form" label-width="160px">
        <el-form-item label="站点名称">
          <el-input v-model="form.site_name"></el-input>
        </el-form-item>
        <el-form-item label="网站LOGO">
          <upload :url="form.logo" :filed="'logo'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="二维码">
          <upload :url="form.ewm" :filed="'ewm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="网站备案号">
          <el-input v-model="form.record"></el-input>
        </el-form-item>
        <el-form-item label="公安备案号">
          <el-input v-model="form.garecord"></el-input>
        </el-form-item>
        <el-form-item label="网站客服电话">
          <el-input v-model="form.kefu"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="form.company_name"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="form.company_address"></el-input>
        </el-form-item>
        <el-form-item label="公司邮箱">
          <el-input v-model="form.company_mail"></el-input>
        </el-form-item>
        <el-form-item label="公司电话">
          <el-input v-model="form.company_phone"></el-input>
        </el-form-item>
        <el-form-item label="版权信息">
          <el-input v-model="form.copyright"></el-input>
        </el-form-item>
        <el-form-item label="网上展览前端域名">
          <el-input v-model="form.exhibition"></el-input>
        </el-form-item>
        <el-form-item label="会议壳前端域名">
          <el-input v-model="form.shell"></el-input>
        </el-form-item>
        <el-form-item label="全景项目前端域名">
          <el-input v-model="form.panorama"></el-input>
        </el-form-item>
        <el-form-item label="会议室前端域名">
          <el-input v-model="form.meeting"></el-input>
        </el-form-item>
        <el-form-item label="直播间前端域名">
          <el-input v-model="form.live"></el-input>
        </el-form-item>
        <el-form-item label="客服中心前端域名">
          <el-input v-model="form.service"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'Site',
  components: {
    Upload
  },
  data () {
    return {
      form: {
        site_name: '',
        logo: '',
        ewm: '',
        record: '',
        garecord: '',
        kefu: '',
        company_name: '',
        company_address: '',
        company_mail: '',
        company_phone: '',
        copyright: '',
        exhibition: '',
        shell: '',
        panorama: '',
        meeting: '',
        live: '',
        service: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取信息
    async getInfo () {
      const { data: res } = await this.$http.get('site')
      if (res.status === 200) {
        this.form.site_name = res.data.site_name
        this.form.logo = res.data.logo
        this.form.ewm = res.data.ewm
        this.form.record = res.data.record
        this.form.garecord = res.data.garecord
        this.form.kefu = res.data.kefu
        this.form.company_name = res.data.company_name
        this.form.company_address = res.data.company_address
        this.form.company_mail = res.data.company_mail
        this.form.company_phone = res.data.company_phone
        this.form.copyright = res.data.copyright
        this.form.exhibition = res.data.exhibition
        this.form.shell = res.data.shell
        this.form.panorama = res.data.panorama
        this.form.meeting = res.data.meeting
        this.form.live = res.data.live
        this.form.service = res.data.service
      } else {
        this.$message.error(res.msg)
      }
    },
    // 提交表单
    async onSubmit () {
      const { data: res } = await this.$http.post('site', this.form)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.form[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.form[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.form[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.form[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  width: 800px;
  margin-right: auto;
  margin-left: auto;
}
</style>
